<template>
  <el-card class="box-card">
    <div slot="header">
      <span>{{ $tc('entity.newsletter', 2) }}</span>
      <router-link
        v-if="userReadOnly"
        tag="button"
        class="card-header-button el-button el-button--primary el-button--mini"
        to="/newsletters/create"
      >
        <i class="fad fa-plus"></i>&nbsp;{{ $t('create') }}
      </router-link>
    </div>
    <el-form :inline="true">
      <el-form-item>
        <select-service v-model="selectedService"></select-service>
      </el-form-item>
      <el-form-item>
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-search"
          @click="get"
        >
          Buscar
        </el-button>
      </el-form-item>
    </el-form>
    <br />
    <el-table
      v-loading="loading"
      style="margin-top: 8px; width: 100%"
      :data="newsletters"
      stripe
      border
    >
      <el-table-column
        prop="newsletterSubject"
        sortable
        :label="$t('subject')"
      ></el-table-column>
      <el-table-column
        prop="newsletterContent"
        sortable
        :label="$t('content')"
        width="160px"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="info"
            size="mini"
            @click.prevent="viewModal(scope.row)"
          >
            <i class="fa fa-search"></i>Ver
          </el-button>
        </template>
      </el-table-column>
      <el-table-column :label="$t('created')" width="160px" align="center">
        <template slot-scope="scope">
          {{ scope.row.newsletterCreatedAt | formatToDateTime }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageNumber"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="rowsCount"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <el-dialog :title="dialogTitle" :visible.sync="dialog" center>
      {{ dialogContent }}
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="dialog = false">
          Cerrar
        </el-button>
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
export default {
  data() {
    return {
      newsletters: [],
      selectedService: '',
      loading: false,

      /* PAGINATE */
      pageCount: 0,
      pageSize: 10,
      pageNumber: 1,
      rowsCount: 0,
      skip: 0,
      dialog: false,
      dialogTitle: '',
      dialogContent: '',
    }
  },
  computed: {
    roleId() {
      return JSON.parse(localStorage.getItem('masters')).roleId
    },
    userReadOnly() {
      return this.roleId.localeCompare('1') === 0
    },
  },
  mounted() {
    this.get()
  },
  methods: {
    viewModal(data) {
      const _this = this
      _this.dialog = true
      _this.dialogTitle = data.newsletterSubject
      _this.dialogContent = data.newsletterContent
    },
    get() {
      const _this = this
      _this.skip = (parseInt(_this.pageNumber) - 1) * parseInt(_this.pageSize)
      if (parseInt(_this.top) === parseInt(_this.skip)) {
        _this.skip = 0
      }
      _this.loading = true
      const serviceId = _this.selectedService ? _this.selectedService : 0
      _this.$http
        .get(
          `newsletters/findByService?serviceId=${serviceId}&top=${_this.pageSize}&skip=${_this.skip}`,
        )
        .then(
          response => {
            _this.newsletters = response.body.data
            _this.rowsCount = response.body.count
            _this.pageCount = Math.ceil(_this.rowsCount / _this.pageSize)
            if (_this.pageCount > 0 && _this.pageCount < _this.pageNumber) {
              _this.pageNumber = 1
            }
            _this.loading = false
          },
          response => {
            _this.loading = false
          },
        )
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.get()
    },
    handleCurrentChange(val) {
      this.pageNumber = val
      this.get()
    },
  },
}
</script>
